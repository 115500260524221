// StarRating.js
import React from 'react';
import { Rating } from '@mui/material';

const StarRating = ({ value, onChange }) => {
  return (
    <Rating
      name="rating"
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      precision={1} // Allow half-star ratings
    />
  );
};

export default StarRating;
