import React from 'react';
import './styles.css'; // Import your CSS file for styling
import { Email, Phone } from '@mui/icons-material'; // Using MUI icons
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-left">
        <div className="footer-icons">
          <a href="https://www.facebook.com/profile.php?id=100092635474654" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/aancsealing" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
          <br /> <br />
          <a href="mailto:aandcsealing@gmail.com">
            <Email /> AandCSealing@gmail.com
          </a>
          <a href="tel:+17057953344">
            <Phone /> 705-795-3344
          </a>
          {/* Add other social media icons as needed */}
        </div>
        <div className="footer-contact">
          
          
        </div>
      </div>
      <div className="footer-right">
        <p className="footer-text">
            <br/>
          Alex Lemon, Owner/Operator<br /> © 2024 A&C Sealing & AandCSealing.com
        </p>
      </div>
    </footer>
  );
};

export default Footer;
