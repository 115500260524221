import React, { useEffect } from "react";

import Logo from "../../assets/logoNoBG.png";

import "./styles.css";

function ComingSoon() {
  useEffect(() => {
    document.title = "A&C Sealing | Coming Soon!";
  }, []);

  return (
    <div className="centered-wrapper">
      <div className="cs-container">
        <div className="side-by-side">
          <img
            src={Logo} // Replace with your actual image URL
            alt="Sit tight!"
            className="pageLogo"
          />
        </div>
        <div className="text-container side-by-side">
          <h2>Sit tight!</h2>
          <p>
            We're working hard to bring you a new website in the coming days.
            For now, sit tight, and if you have any questions, feel free to
            reach out via email:{" "}
            <a href="mailto:aandcsealing@gmail.com">aandcsealing@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
