import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { Link } from "react-router-dom";

import Logo from "../../assets/logoNoBG.png";

import "./styles.css";

import "bootstrap/dist/css/bootstrap.min.css";

function BasicExample({ domainName }) {
  return (
    <Navbar
      bg="black"
      data-bs-theme="dark"
      fixed="top"
      expand="lg"
      className="wholeNav"
    >
      <Container>
        <Navbar.Brand href="./">
          <div className="flex-container">
            <div className="flex-child">
              <img src={Logo} alt="website logo" className="navLogo" />
            </div>
            <div className="flex-child">
              <div className="navTitle">A&C Sealing</div>
              <div className="sloganText">The best deal to get it sealed.</div>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="./" style={{ textDecoration: "none" }}>
              <div className="navItem">Home</div>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <div className="navItem">Services</div>
            </Link>
            <Link to="/reviews" style={{ textDecoration: "none" }}>
              <div className="navItem">Reviews</div>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <div className="navItem">Contact</div>
            </Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
