import "./App.css";
import Navbar from "./components/navbar/Navbar";

import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Reviews from "./pages/reviews/Reviews";
import Contact from "./pages/contact/Contact";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import Footer from "./components/footer/Footer";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";

// const [isLoading, setIsLoading] = useState(true);

// let history = useHistory();

// let express = require("express");
// let cors = require("cors");
// let app = express();

// app.use(cors());

const domainName = window.location.hostname;

const path = "/";

if (!window.location.host.startsWith("www")){
  window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
}


function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <Navbar domainName={domainName} className="navBar" />
          <div className="wrap">
              <Routes className="router">
                <Route path={path} exact element={<Home />} />
                <Route path={path + "services"} exact element={<Services />} />
                <Route path={path + "reviews"} exact element={<Reviews />} />
                <Route path={path + "contact"} exact element={<Contact />} />
              </Routes>
            </div>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
