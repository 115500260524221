import React, { useEffect } from "react";

import "./styles.css";

import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";

function Services() {
  const services = [
    {
      title: "Residential and Commercial Sealing",
      description:
        "Protect your pavement with our high-quality sealing services.",
      icon: <BuildOutlinedIcon />,
    },
    {
      title: "Crack Repair",
      description: "Fix cracks in your pavement to prevent further damage.",
      icon: <BuildOutlinedIcon />,
    },
    {
      title: "Asphalt and Pothole Repair",
      description: "We specialize in repairing asphalt and filling potholes.",
      icon: <BuildOutlinedIcon />,
    },
    {
      title: "Driveway Ramps",
      description: "Install ramps for smooth transitions on your driveway.",
      icon: <BuildOutlinedIcon />,
    },
    {
      title: "Edging/Weed Removal",
      description: "Keep your pavement edges clean and weed-free.",
      icon: <BuildOutlinedIcon />,
    },
  ];

  useEffect(() => {
    document.title = "A&C Sealing | Services";
  }, []);

  return (
    <div className="App">
      <div className="services-container">
        <h1 className="our-services-title">Our Services</h1>
        <div className="services-list">
          {services.map((service, index) => (
            <div key={index} className="service-item">
              <div className="">
                <div className="service-icon service-item-title">
                  {service.icon}
                </div>
                <h3 className="service-title service-item-title">
                  {service.title}
                </h3>
              </div>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
        <p className="contact-info">
          Please note, prices may vary depending on the size and condition of the driveway. A 1-Season warranty is included with every job.
          <br />
          To get a free quote, please{" "}
          <a href="tel:+17057953344">call</a> or <a href="mailto:aandcsealing@gmail.com">email</a> A&C Sealing.
        </p>
      </div>
    </div>
  );
}

export default Services;
