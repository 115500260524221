import React, { useEffect } from "react";
import "./styles.css";

function Contact() {
  useEffect(() => {
    document.title = "A&C Sealing | Contacts";
  }, []);

  const contactData = {
    address: "",
    email: "aandcsealing@gmail.com",
    phoneNumber: "7057953344",
    serviceAreas:
      "Barrie, Orillia, Angus, Innisfil, Alliston, and surrounding areas.",
    hours: [
      {
        day: "Monday",
        hours: "Closed",
      },
      {
        day: "Tuesday",
        hours: "9am-6pm",
      },
      {
        day: "Wednesday",
        hours: "9am-6pm",
      },
      {
        day: "Thursday",
        hours: "9am-6pm",
      },
      {
        day: "Friday",
        hours: "9am-6pm",
      },
      {
        day: "Saturday",
        hours: "9am-6pm",
      },
      {
        day: "Sunday",
        hours: "9am-6pm",
      },
    ],
  };

  return (
    <div className="App">
      <div className="pageTitle centered">Contact A&C</div>
      <div className="contact-page">
        {/* <div className="contact-item">
          <strong>Address:</strong> {contactData.address}
        </div> */}
        <div className="contact-item">
          <div className="itemTitle">Email:</div>{" "}
          <a className="hrefClick" href="mailto:aandcsealing@gmail.com">{contactData.email}</a>
        </div>
        <div className="contact-item">
          <div className="itemTitle">Phone Number:</div> <a className="hrefClick" href={`tel:${contactData.phoneNumber}`}>705-795-3344</a>
        </div>
        <div className="contact-item">
          <div className="itemTitle">Service Areas:</div> {contactData.serviceAreas}
        </div>
        <div className="contact-item">
          <div className="itemTitle">Hours:</div>
          {contactData.hours.map((hour) => (
            <div key={hour.day}>
              <div className="hoursSideBySide">
                <strong>{hour.day}:</strong> {hour.hours}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Contact;
