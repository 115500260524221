// FloatingButton.js
import React, { useState } from "react";
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@mui/material";

import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";

import StarRating from "./StarRating"; // Create this component separately

import "./styles.css";

const FloatingButton = ({ formData, setFormData }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRatingChange = (value) => {
    setFormData({ ...formData, rating: value });
  };

  const handleSubmit = async () => {
    // Validate form fields here (e.g., check if all required fields are filled)
    // Create the JSON object and add it to the "reviews" array
    console.log("Form data:", formData);
    let sendingData = new FormData();
    sendingData.append("fName", formData.fName);
    sendingData.append("lName", formData.lName);
    sendingData.append("email", formData.email);
    sendingData.append("rating", formData.rating);
    sendingData.append("review", formData.review);
    console.log(
      `sendingData: ${JSON.stringify({
        fName: formData.fName,
        lName: formData.lName,
        email: formData.email,
        rating: formData.rating,
        review: formData.review,
      })}`
    );
    // axios.post("https://aandcsealing.com/api/reviews/post", sendingData);
    const finalData = JSON.stringify(sendingData);
    await axios
      .post("https://www.aandcsealing.com/api/reviews/post/index.php", {
        fName: formData.fName,
        lName: formData.lName,
        email: formData.email,
        rating: formData.rating,
        review: formData.review,
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        proxy: {
          host: 'https://www.aandcsealing.com',
          port: 2083,
        }
      })
      .then(
        (response) => {
          console.log(`response.data: ${JSON.stringify(response.data)}`);
          window.location.reload();
        },
        (error) => {
          console.log(
            `error: ${error}, ${JSON.stringify(error.response)}, ${
              error.request
            }, ${error.message}`
          );
        }
      );

    // Reset form fields
    setFormData({
      fName: "",
      lName: "",
      email: "",
      rating: null,
      review: "",
    });
    handleClose();
  };

  return (
    <div>
      {/* <Fab className="floating-button" color="primary" onClick={handleOpen}>
        Write a Review
      </Fab> */}
      <div className="floating-button">
        <div id="reviewButton" onClick={handleOpen}>
          Write a Review
        </div>
      </div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason == "backdropClick") {
            return;
          } else {
            handleClose();
          }
        }}
        disableBackdropClick
      >
        <DialogTitle>Write a Review</DialogTitle>
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: 5, right: 5, color: "black" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div style={{ display: "flex", gap: "16px", marginTop: "5px" }}>
              <TextField
                label="First Name"
                fullWidth
                value={formData.fName}
                onChange={(e) =>
                  setFormData({ ...formData, fName: e.target.value })
                }
              />
              <TextField
                label="Last Name"
                fullWidth
                value={formData.lName}
                onChange={(e) =>
                  setFormData({ ...formData, lName: e.target.value })
                }
              />
            </div>

            <TextField
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />

            <div style={{ display: "flex" }}>
              <div style={{ paddingRight: "10px" }}>Star Rating (1-5)</div>
              <StarRating
                value={formData.rating}
                onChange={handleRatingChange}
              />
            </div>

            <TextField
              label="Review"
              multiline
              rows={4}
              fullWidth
              value={formData.review}
              onChange={(e) =>
                setFormData({ ...formData, review: e.target.value })
              }
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !formData.fName ||
                !formData.lName ||
                !formData.email ||
                !formData.rating ||
                !formData.review
              }
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FloatingButton;
