import React, { useEffect, useState } from "react";

import "./styles.css";

import Logo from "../../assets/logoNoBG.png";

function Home() {
  const importAll = (r) => r.keys().map(r);
  const interval = 5000;

  const [beforeandafter, setBeforeAndAfter] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [images, setImages] = useState([
    ...importAll(
      require.context("../../assets/photo-library", true, /\.(png|jpe?g|svg)$/)
    ),
  ]);

  useEffect(() => {
    document.title = "A&C Sealing | Home";
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % beforeandafter.length);
    }, interval);
    return () => clearInterval(timer);
  }, [beforeandafter]);

  useEffect(() => {
    console.log("images", images);
    setBeforeAndAfter([
      {
        id: 0,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home1_before")),
        afterImg: images.find((img) => img.includes("home1_after")),
      },
      {
        id: 1,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home2_before")),
        afterImg: images.find((img) => img.includes("home2_after")),
      },
      {
        id: 2,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home3_before")),
        afterImg: images.find((img) => img.includes("home3_after")),
      },
      {
        id: 3,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home4_before")),
        afterImg: images.find((img) => img.includes("home4_after")),
      },
      {
        id: 4,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home5_before")),
        afterImg: images.find((img) => img.includes("home5_after")),
      },
      {
        id: 5,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home6_before")),
        afterImg: images.find((img) => img.includes("home6_after")),
      },
      {
        id: 6,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home7_before")),
        afterImg: images.find((img) => img.includes("home7_after")),
      },
      {
        id: 7,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home8_before")),
        afterImg: images.find((img) => img.includes("home8_after")),
      },
      {
        id: 8,
        type: "beforeandafter",
        beforeDesc: "Before",
        afterDesc: "After",
        beforeImg: images.find((img) => img.includes("home9_before")),
        afterImg: images.find((img) => img.includes("home9_after")),
      },
    ]);
  }, [images]);

  return (
    <div className="App">
      <div className="home-container">
        <div className="inline">
          <img src={Logo} alt="website logo" className="homeLogo" />
        </div>
        <div className="inline">
          <div className="homeTitle">A&C Sealing</div>
          <div className="homeSubTitle">The best deal to get it sealed.</div>
        </div>
      </div>
      <div>
        Welcome to A&C Sealing: The best deal to get it sealed! <br />
        My name is Alex Lemon, and I take pride in being the Owner/Operator at
        A&C Sealing, and I have over 5 years of experience in the industry.{" "}
        <br />
        Whether it’s your driveway or a commercial property, we’re here to make
        it look its best this summer. Our services include:
        <ul>
          <li>Residential and Commercial Sealing</li>
          <li>Crack Repair</li>
          <li>Asphalt and Pothole Repair</li>
          <li>Driveway Ramps</li>
          <li>Edging/Weed Removal</li>
        </ul>
        Ready to enhance your property? Give us a{" "}
        <a href="tel:7057953344">call</a> today for a free quote. We stand by
        our work and offer a 1-season warranty on every job.
      </div>
      <div className="img-carosel-container">
        <section className="slideshow">
          <div className="slideshowTitle">Here is some of our work...</div>
          <div className="slide-holder">
            {beforeandafter.length !== 0 && (
              <div className={`photoSlideShow photoID${currentSlide}`}>
                <div>
                  <img
                    className="slideshow-img"
                    src={beforeandafter[currentSlide].beforeImg}
                    alt="before"
                  />
                  <div className="itemDesc">{beforeandafter[currentSlide].beforeDesc}</div>
                </div>
                <div>
                  <img
                    className="slideshow-img"
                    src={beforeandafter[currentSlide].afterImg}
                    alt="after"
                  />
                  <div className="itemDesc">{beforeandafter[currentSlide].afterDesc}</div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
