import React, { useEffect, useState } from "react";
import FloatingButton from "./FloatingButton";

import * as process from "process";

import axios from "axios";

import "./styles.css";

function Reviews() {

  window.process = process;
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    rating: null,
    review: "",
  });

  const [reviews, setReviews] = useState([]);

  const dummyReviews = [
    {
      fName: "John",
      lName: "Doe",
      email: "john@example.com",
      rating: 5,
      review:
        "I recently purchased this product, and I must say it's outstanding! The quality is top-notch, and it exceeded my expectations. Highly recommended.",
    },
    {
      fName: "Nathan",
      lName: "Kennedy",
      email: "nathan630pm@outlook.com",
      rating: 5,
      review:
        "yeah bro I generated this site with AI and I give that shit a 5/5 star rating. The service was shit tho as I don't have a driveway.",
    },
    {
      fName: "Jane",
      lName: "Smith",
      email: "jane@example.com",
      rating: 4,
      review:
        "The product is good overall, but there's room for improvement. It's sturdy, but I expected a bit more.",
    },
    {
      fName: "Alice",
      lName: "Johnson",
      email: "alice@example.com",
      rating: 4,
      review:
        "I'm satisfied with this purchase. It serves its purpose well, and the design is appealing.",
    },
    {
      fName: "Michael",
      lName: "Smith",
      email: "michael@example.com",
      rating: 3,
      review:
        "The quality is average, nothing exceptional. It's functional, but not outstanding.",
    },
    {
      fName: "Olivia",
      lName: "Brown",
      email: "olivia@example.com",
      rating: 5,
      review:
        "I absolutely loved it! The product exceeded my expectations. Five stars!",
    },
    {
      fName: "William",
      lName: "Johnson",
      email: "william@example.com",
      rating: 4,
      review:
        "Solid product, worth the price. It's reliable and performs well.",
    },
    {
      fName: "Sophia",
      lName: "Lee",
      email: "sophia@example.com",
      rating: 5,
      review:
        "Top-notch quality and great service! I'm impressed with the attention to detail.",
    },
    {
      fName: "Ethan",
      lName: "Miller",
      email: "ethan@example.com",
      rating: 3,
      review:
        "Decent, but there's room for improvement. It's functional, but lacks that 'wow' factor.",
    },
    {
      fName: "Ava",
      lName: "Garcia",
      email: "ava@example.com",
      rating: 5,
      review:
        "Fantastic experience. Will buy again without hesitation. The customer support was excellent.",
    },
    {
      fName: "Liam",
      lName: "Wilson",
      email: "liam@example.com",
      rating: 4,
      review: "Good value for money. It's reliable and meets my needs.",
    },
    {
      fName: "Emma",
      lName: "Clark",
      email: "emma@example.com",
      rating: 2,
      review:
        "Disappointing. Not what I was hoping for. The quality fell short of my expectations.",
    },
    {
      fName: "Daniel",
      lName: "Martin",
      email: "daniel@example.com",
      rating: 1,
      review: "Terrible quality. Avoid at all costs. I regret this purchase.",
    },
  ];

  useEffect(() => {
    document.title = "A&C Sealing | Reviews";
  }, []);

  const getReviews = async () => {
    await axios.get(`https://www.aandcsealing.com/api/reviews/get`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      proxy: {
        host: 'https://www.aandcsealing.com',
        port: 2083,
      }
    }).then((response) => {
      setReviews([...response.data]);
      console.log(`response.data: ${JSON.stringify(response.data)}`);
    });
  };
  

  const dateTimeFormatter = (date) => {
    console.log(`date: ${date}`);
    const dateObject = new Date(Date.parse(date));

    console.log(dateObject);
    // const formattedDate = dateObject.toLocaleDateString(); // e.g., "9/25/2023"
    let options = {timeZone: 'America/New_York'};
    const formattedMonthDayYear = dateObject.toLocaleDateString('en-US');
    const formattedTime = dateObject.toLocaleTimeString(); // e.g., "3:30:45 PM"

    return (
      <div>
        <div className="reviewDate">{formattedMonthDayYear}</div>
      </div>
    )
  }

  useEffect(() => {
    getReviews();
  }, []);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const starIcons = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        starIcons.push(
          <span key={i} className="star">
            &#9733;
          </span>
        );
      } else if (hasHalfStar) {
        starIcons.push(
          <span key={i} className="star half">
            &#9733;
          </span>
        );
        hasHalfStar = false;
      } else {
        starIcons.push(
          <span key={i} className="star outline">
            &#9734;
          </span>
        );
      }
    }

    return starIcons;
  };

  return (
    <div className="App">
      <FloatingButton formData={formData} setFormData={setFormData} />
      <div className="reviews-container">
      <h1 className="our-reviews-title">Reviews</h1>
        <div className="reviews-grid">
          {reviews.map((review, index) => (
            <div key={index} className="review-item">
              <strong className="review-name">
                {review.fName} {review.lName}
              </strong>
              <br />
              <div>{dateTimeFormatter(review.createdAt)}</div>
              <span className="review-rating">
                {renderStars(review.rating)}
              </span>
              <br />
              <p className="review-text">{review.review}</p>
            </div>
          ))}
        </div>
      </div>
      <div style={{display: "none"}}>This is a test block that should not show on the website, but in the developer options.</div>
    </div>
  );
}

export default Reviews;
